import React, { useContext } from "react";
import { Dropdown, Image } from "react-bootstrap";
import userIcon from "../assets/icon_user.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContextProvider";

function ProfileIcon({ className }) {
  const navigate = useNavigate();
  const { logout } = useContext(UserContext);
  
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <Dropdown id="profile-icon" align="end" className={className}>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-button-drop-down-centered"
          className="p-0 border-0 rounded-circle"
        >
          <Image src={userIcon} roundedCircle width="40" height="40" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={()=>navigate("/userProfile")}>My Profile</Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default ProfileIcon;
