import React, { memo } from "react";
import { ContributionCalendar } from "react-contribution-calendar";
import "../styles/Components.css";

function CustomActivityCalender({ values }) {
  console.log(values);
  const data = values?.map((value) => {
    let level = 0;
    if (value.count >= 1 && value.count <= 2) level = 1;
    else if (value.count >= 3 && value.count <= 5) level = 2;
    else if (value.count >= 6 && value.count <= 10) level = 3;
    else if (value.count >= 11 ) level = 4;

    return {
      [value.date]: {
        level: level, // 0-4 number for color level
        data: value.count
      },
    };
  });


  
  const endDate = (new Date()).toISOString().split('T')[0]
  const startDate = new Date(new Date().setDate(new Date().getDate() - 364)).toISOString().split('T')[0]

  return (
    <div id="custom-activity-calender" className="p-3">
      <h4>Activity</h4>
      <p className="text-secondary">
        Public activity on Hyvv includes publishing content, making submissions,
        and data set submission
      </p>
      <div className="calendar-box d-flex justify-content-center px-3 px-md-0">
        <ContributionCalendar
          key={`${new Date().getTime()}`}
          data={data}
          daysOfTheWeek={["", "", "", "", "", "", ""]}
          start={startDate}
          end={endDate} 
          textColor="#7C7878"
          theme="grass" // https://www.npmjs.com/package/react-contribution-calendar for more themes
          cx={12}
          cy={12}
          cr={6}
          onCellClick={(e, data) => console.log(data)}
        />
      </div>
    </div>
  );
}

export default memo(CustomActivityCalender);
