import React, { useEffect, useState } from "react";
import BreadCrumb from "../components/BreadCrumb";
import { Col, Row, Stack } from "react-bootstrap";
import "../styles/Home.css";
import profile from "../assets/u_avatar.png";
import { LuPenSquare } from "react-icons/lu";
import { MdOutlineFileDownload } from "react-icons/md";
import dustbin from "../assets/dustbin.png";
import pdfImage from "../assets/pdf-image.png";
import CustomDropzone from "../components/CustomDropzone";
import { useParams } from "react-router-dom";
import { deleteSupplementaryMaterial, getPaperById } from "../api/Api";
import dz_image from "../assets/dz-image.png";
import CustomSkeleton from "../components/CustomSkeleton";

const array = [
  { title: "Home", path: "/" },
  { title: "Supplementary Materials", path: "/supplementary-materials" },
];

function SupplementaryMaterials() {
  const [uploadData, setUploadData] = useState(null);
  const [paper, setPaper] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { paperId } = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchPaper = async () => {
      try {
        const response = await getPaperById(paperId);
        if (response.status === 200) {
            console.log(response.data)
          setPaper(response.data);
        } else {
          setError(response.data);
        }
      } catch (error) {
        setError(error);
        console.error("Error fetching paper:", error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchPaper();
  }, [paperId, uploadData]);

  const handleDelete = async (fileId) => {
    try {
      const response = await deleteSupplementaryMaterial(paperId, fileId);
      if (response.status === 200) {
        setPaper((prevPaper) => ({
          ...prevPaper,
          supplementary_materials: prevPaper.supplementary_materials.filter(
            (material) => material.id !== fileId
          ),
        }));
      } else {
        setError(response.data);
      }
    } catch (error) {
      setError(error);
      console.error("Error deleting supplementary material:", error);
    }
  };

  if (error) return <div>Error: {error.message}</div>; // Render error message if any

  return (
    <div id="supplementary-materials-page" className="m-4 m-sm-5">
      {loading ? <CustomSkeleton width={200} /> :<BreadCrumb array={array} />}
      <div className="mt-4">
        <Row className="mx-0">
          <Col lg={8} className="mt-4 mt-sm-0 pe-lg-3">
            <h3 className="mb-3 text-break">{loading ? <CustomSkeleton width={200} /> : (paper?.name || "Paper Title")}</h3>
            <div className="d-flex">
              {loading ? (
                <CustomSkeleton width={50} height={50} circle={true} />
              ) : (
                <img src={profile} className={'supplementary-avatar'} alt="profile" />
              )}
              <span className="ps-3 align-content-center">
                {loading ? <CustomSkeleton width={100} /> : paper?.author}
              </span>
            </div>

            {/* status */}
            <Stack
              direction="horizontal"
              gap={3}
              style={{ backgroundColor: "#FFF1C2" }}
              className="my-3 py-1 px-3 d-inline-flex flex-row"
            >
              <div className="fw-bold">Status:</div>
              <div className="fs-6">
                {loading ? <CustomSkeleton width={100} /> : (paper?.status || "Status")}
              </div>
              <LuPenSquare size={18} strokeWidth={3} />
            </Stack>
            <h5 className="mt-3">{loading ? <CustomSkeleton width={300} /> :"Supplementary Materials"}</h5>
            <p className="text-secondary">
              {loading ? <CustomSkeleton width={300} /> : "Here are all the relevant artifacts for this work."}
            </p>

            {loading ? (
              <CustomSkeleton height={150} />
            ) : (
              <CustomDropzone
                className="supplementary-dz my-4"
                setUploadData={setUploadData}
                initUrl={`${process.env.REACT_APP_BACKEND_BASE_URL}/papers/${paperId}/supplementary-materials`}
                destroyDep={[]}
                children={<Components />}
              />
            )}

            {loading ? (
              <CustomSkeleton count={3} height={60} />
            ) : (
              paper?.supplementary_materials?.length > 0 ? (
                paper.supplementary_materials.map((material) => (
                  <Stack
                    key={material.id}
                    direction="horizontal"
                    className="px-3 border rounded-1 mt-3 gap-2 gap-sm-4"
                  >
                    <div className="text-secondary overflow-hidden">{material.name}</div>
                    <div className="text-secondary ms-auto">{material.file_size}</div>
                    <button onClick={() => window.open(`${process.env.REACT_APP_BACKEND_BASE_URL}/media/papers/supplementary/${material?.file_id}`)} className="btn btn-outline-secondary my-1 d-inline-flex gap-3">
                      <MdOutlineFileDownload size={25} />
                      <div>Download</div>
                    </button>
                    <img
                      src={dustbin}
                      alt="delete"
                      onClick={() => handleDelete(material.id)}
                      style={{ cursor: "pointer" }}
                    />
                  </Stack>
                ))
              ) : (
                <div>No supplementary materials available.</div>
              )
            )}
          </Col>

          <Col lg={4} className="mt-5 mt-lg-0">
            <Stack direction="horizontal" gap={3} className="my-4 my-lg-0">
              {loading ? (
                <CustomSkeleton width={100} height={120} />
              ) : (
                <img src={pdfImage} alt="pdf" style={{ width: "100px" }} />
              )}
              <Stack>
                <h6 className="opacity-75 text-break">
                  {loading ? <CustomSkeleton width={150} /> : (paper?.name || "Paper Title")}
                </h6>
                <Stack direction="horizontal" gap={3} className="text-secondary">
                  {loading ? (
                    <>
                      <CustomSkeleton width={50} />
                      <CustomSkeleton width={50} />
                    </>
                  ) : (
                    <>
                      <div>{paper?.size || "Size"}</div>
                      <div style={{ color: "lightgreen" }}>{paper?.status}</div>
                      <a href={`${process.env.REACT_APP_BACKEND_BASE_URL}/media/papers/${paper?.file_id}`} className="text-color-cyan">
                        Download
                      </a>
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SupplementaryMaterials;

const Components = () => {
    return (
        <Stack direction="horizontal" className="text-start p-sm-4 gap-3 gap-sm-4">
        <img
          src={dz_image}
          alt="dz-image"
          style={{ stroke: "ActiveBorder" }}
        />
        <div>
          <h6>Darg and Drop your files here</h6>
          <div className="text-secondary">
            File formats Supported: pdf, csv, xlsx, jpg, png, gif,
            zip, mp3, mp4, pptx
          </div>
        </div>
      </Stack>
    )
}