import React, { useState } from "react";
import { Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../contexts/UserContextProvider";
import Logo from "./Logo";
import ProfileIcon from "./ProfileIcon";
import "../styles/Custom.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import SideMenu from "./SideMenu";
import MenuIcon from "@mui/icons-material/Menu";

export default function NavigationBar() {
  const { isAuth } = useContext(UserContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const checkAuth = () => {
      if (!token) {
        navigate("/");
      }
    };

    checkAuth();
  }, [isAuth, navigate]);

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Stack direction="horizontal" gap={2} className="p-3 m-1 border-bottom">
        <Logo />
        <DashboardButton />
      </Stack>
      <SideMenu />
    </Box>
  );

  return (
    <nav id="navigation" className="border-bottom">
      <Stack className="py-3 px-4 px-md-4" gap={4} direction="horizontal">
        <div className="d-md-none" onClick={toggleDrawer(true)}>
          <MenuIcon />
        </div>
        <Logo className="d-none d-md-flex" />

        <DashboardButton />
        <ProfileIcon />
      </Stack>

      {/* drawer */}
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </nav>
  );
}

// DashboardButton for the navigation bar
const DashboardButton = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ cursor: "pointer" }}
      className="ms-auto"
      onClick={() => navigate("/home")}
    >
      Dashboard
    </div>
  );
};
