import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

// purpose of this layout is to have a layout with two columns
// main content and right side content 
function InnerLayout({children1, children2}) {

  return (
    <Container>
    <Row sm lg>
      <Col className="py-3 px-4 p-md-5">
        {children1}
      </Col>
      <Col sm={4} lg={3} className='d-none d-lg-block' style={{maxWidth:'300px'}}>
        <aside>
          {children2}
        </aside>
      </Col>
    </Row>
  </Container>
  )
}

export default InnerLayout