import React, { useContext } from "react";
import RegisterModal from "../modals/RegisterModal";
import Logo from "../Logo";
import { ModelContext } from "../../contexts/ModelContextProvider";
import LoginForm from "../forms/LoginForm";
import CheckYourInbox from "../modals/CheckYourInbox";
import { UserContext } from "../../contexts/UserContextProvider";


const LoginCard = () => {
  const { setOpen, isOpenVerifyAlert, VerifyAlertClose } = useContext(ModelContext);
  const { userInfo } = useContext(UserContext);
  return (
    <section id="l-card-container" className="lr-card">
      <div className="row lr-card-dimensions center">
        <div className="col-md-6 lr-card-left-container p-5 p-md-6 d-flex flex-column justify-content-between">
          <div className="pt-md-5">
            <Logo
              style={{ width: "70px", height: "70px", borderRadius: "20px" }}
            />
            <h3 className="pt-4 mh-80">
              Facilitating research publishing from start to finish
            </h3>
          </div>
          <small>
            New to Puplier? Create a new account{" "}
            <span onClick={setOpen} style={{ cursor: "pointer" }}>
              here.
            </span>
          </small>
          <RegisterModal />
        </div>

        <div className="col-md-6 p-5 p-md-6 d-flex align-items-center lr-card-right-container">
          <LoginForm />
        </div>
      </div>
      {/* alert model for user */}
      {isOpenVerifyAlert && <CheckYourInbox
        open={isOpenVerifyAlert}
        setClose={VerifyAlertClose}
        onClick={VerifyAlertClose}
        email={userInfo?.email}
      />}
    </section>
  );
};

export default LoginCard;
