import * as React from "react";
import { Modal } from "react-bootstrap";
import DarkButton from "../DarkButton";
import "../../styles/reviewer/Components.css";

const ReferencePreviewModel = ({references,open, setClose}) => {
    return (
        <Modal
            style={{ top: "5%"}}
            className="overflow-hidden"
            size="xl"
            show={open}
            onHide={setClose}
        >
            <Modal.Header style={{ borderBottom: 0 }} closeButton>
                <Modal.Title>
                    <strong className="fs-5">Formatted references</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-100 text-w pb-0">

                <ol className="w-100 overflow-x-hidden overflow-y-scroll" style={{height:'50vh'}}>
                    {references.map((reference, index) => (
                        <li
                            key={index}
                            className="w-100 text-wrap mb-3"
                            style={{ wordBreak: 'break-word', overflowWrap: 'anywhere' }}
                        >
                            {reference}
                        </li>
                    ))}
                </ol>

            </Modal.Body>

            <Modal.Footer className="justify-content-end border-0 pt-0">
                <DarkButton
                    title={"Close"}
                    variant={"btn-outline-dark"}
                    className="m-0"
                    onClick={setClose}
                />
            </Modal.Footer>

        </Modal>
    )
}

export default ReferencePreviewModel
