import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import RegisterCard from "../cards/RegisterCard";
import { ModelContext } from "../../contexts/ModelContextProvider";

function RegisterModal() {
  const { isOpen, setClose } = useContext(ModelContext);
  return (
    <Modal
      className="register-model-container"
      show={isOpen}
      onHide={setClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <RegisterCard />
    </Modal>
  );
}

export default RegisterModal;
