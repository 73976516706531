import DarkButton from "../DarkButton";

const ReviewerCard = ({
  id,
  title,
  author,
  time,
  onButtonClick,
  onClickCard,
  buttonTitle,
}) => {
  if (time) {
    const timeDifference = new Date() - new Date(time);
    time = timeDifference / (1000 * 60 * 60);
    if (time < 24) {
      time = Math.floor(time) + " hours ago";
    } else {
      time = Math.floor(time / 24) + " days ago";
    }
  }
  return (
    <div className="card border-0 w-100 mb-3" style={{ maxWidth: "40rem" }}>
      <div className="card-body d-flex gap-4 justify-content-between">
        <div
          onClick={() => onClickCard(id)}
          className="unassigned-card"
          style={{ cursor: "pointer" }}
        >
          <p className="card-title mb-1 paper-card-text" style={{ textTransform: "uppercase"}}>
            <strong>{title}</strong>
          </p>
          <p className="text-body-secondary lh-sm mb-1">
            <em>{author}</em>
          </p>
          <p className="text-body-secondary lh-sm mb-1">
            <em>{time}</em>
          </p>
        </div>
        <div>
          <DarkButton title={buttonTitle} onClick={() => onButtonClick(id)} />
        </div>
      </div>
    </div>
  );
};

export default ReviewerCard;
