import React, { useState } from "react";
import "../styles/Home.css";
import CustomButton from "../components/CustomButton";
import CreateNewProject from "../components/modals/CreateNewProject";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import imageArrow from "../assets/imageArrow.png";

function Home() {
  const [show, setShow] = useState(false);
  const handleOpenModel = () => setShow(true);
  const close = () => setShow(false);

  return (
    <section id="home" className="p-4 p-md-5">
      <div className="d-flex justify-content-end pb-md-5">
        <CustomButton
          rounded={true}
          onClick={handleOpenModel}
          pre={<AddRoundedIcon sx={{ width: 20 }} />}
        >
          NEW PROJECT
        </CustomButton>
      </div>
      <div className="text-center mt-5">
        <div>Select a project from the right side bar to get started</div>
        <img style={{ width: "150px" }} src={imageArrow} alt="Arrow" />
      </div>
      <CreateNewProject show={show} handleClose={close} />
    </section>
  );
}

export default Home;
