import { Stack } from 'react-bootstrap';
import "../../styles/reviewer/ReviewerNavBar.css";
import Logo from '../Logo';
import ProfileIcon from '../ProfileIcon';
import React, {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ReviewerSidebar from "./ReviewerSidebar";

export default function ReviewerNavBar() {

    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <Stack direction="horizontal" gap={2} className="p-3 m-1 border-bottom">
                <Logo />
            </Stack>
            <ReviewerSidebar />
        </Box>
    );

  return (
    <nav id="reviewer-navigation" className="border-bottom">
      <Stack className="mx-md-5 me-5 p-3" gap={4} direction="horizontal">
          <div className="d-md-none" onClick={toggleDrawer(true)}>
              <MenuIcon/>
          </div>
          <Logo className="d-none d-md-flex"/>
          <ProfileIcon className={"ms-auto"}/>
      </Stack>

        {/* drawer */}
        <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
        </Drawer>
    </nav>
  );
}
