import image from "../assets/Group 274.png";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function BreadCrumb({ array,style,className }) {
  const breadcrumbs = array.map((item, index) =>
    index === array.length - 1 ? (
      <Typography key={index} style={{ fontSize: "13px" ,textTransform:"capitalize"}} color="textPrimary">
        {item.title}
      </Typography>
    ) : (
      <Link
        key={index}
        color="inherit"
        href={item.link}
        style={{ fontSize: "13px", textDecoration: "none",textTransform:"capitalize" }}
      >
        {item.title}
      </Link>
    )
  );

  return (
    <div id="bread-crumb" style={style} className={className}>
      <Stack spacing={2} direction={"row"}>
        <img width={20} height={20} src={image} alt="breadcrumb-image" />
        <Breadcrumbs
          style={{alignItems:"center"}}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
    </div>
  );
}

// Example array
// const array = [
//   { title: "Core", link: "/material-ui/getting-started/installation/" },
//   { title: "Breadcrumb", link: "/material-ui/getting-started/installation/" },
// ];
