import React, { useContext } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import { Col, Row } from "react-bootstrap";
import { addMemberToProject } from "../../api/Api";
import { InitiativeContext } from "../../contexts/InitiativeContextProvider";

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Invalid email address")
    .required("*Email is required"),
  role: Yup.string()
    .oneOf(["admin", "contributor"], "*Invalid role")
    .required("*Role is required"),
});

function AddNewMember({ show, handleClose, project_id }) {
  const { refresh } = useContext(InitiativeContext);
  const handleAddMember = async (values, { setSubmitting, setErrors }) => {
    const payload = {
      email: values.email,
      role: values.role,
    };
    
    addMemberToProject(project_id, payload).then((response) => {
      if (response.status === 200) {
        refresh();
        console.log("Project updated successfully");
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      setSubmitting(false);
      handleClose(); // Close modal on success
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
      <Modal.Title style={{ textAlign: "center", marginBottom: "20px" }}>
        Add Member
      </Modal.Title>
      <Modal.Body style={{ textAlign: "center" }}>
        <div className="px-5 pb-4 text-secondary">
          Enter your colleague's email address to add them to this project
        </div>

        {/* Formik Form */}
        <Formik
          initialValues={{ email: "", role: "" }}
          validationSchema={validationSchema}
          onSubmit={handleAddMember}
        >
          {({ isSubmitting, errors }) => (
            <FormikForm style={{ width: "80%", marginInline: "auto" }}>
              <Row className="p-0 gap-2">
                <Col>
                  <Form.Group className="mb-3" controlId="email">
                    <Field
                      as={Form.Control}
                      type="email"
                      name="email"
                      placeholder="example@email.com"
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col xs={3} className="p-0">
                  <Form.Group className="mb-3" controlId="role">
                    <Field as={Form.Select} name="role">
                      <option value="">Role</option>
                      <option value="admin">Admin</option>
                      <option value="contributor">Contributor</option>
                    </Field>
                  </Form.Group>
                </Col>
              </Row>
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger text-start"
              />
              <ErrorMessage
                name="role"
                component="div"
                className="text-danger text-start"
              />
              <Modal.Footer
                style={{
                  border: 0,
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                {errors.submit && (
                  <p className="text-danger">{errors.submit}</p>
                )}
                <CustomButton
                  rounded={true}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Adding..." : "Add"}
                </CustomButton>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default AddNewMember;
