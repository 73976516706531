import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../styles/reviewer/ReviewerDashboard.css";
import ReviewerCard from "../../components/cards/ReviewerCard";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getReviewerAssignedPapers } from "../../api/Api";

function ReviewerAssignedPapers() {
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Handle view paper
  const handleView = (id) => {
    navigate(`/reviewer/review-paper/${id}`);
  };

  // Fetch assigned papers
  useEffect(() => {
    setLoading(true);
    getReviewerAssignedPapers().then((response) => {
      console.log(response);
      if (response.status === 200) setPapers(response.data);
      else setError(response.message);
    }).catch((error) => {
      console.error("Error fetching data:", error);
      setError(error.message);
    })
    setLoading(false);
  }, []);

  
  if (loading) return <p className="d-flex justify-content-center my-4">Loading...</p>;
  if (error) return <p className="d-flex justify-content-center m-4 alert alert-danger">{error}</p>;
  return (
    <>
      <Row className="d-flex justify-content-center my-4">
        <Col sm={10} lg={8}>
          {papers.map((paper) => (
            <ReviewerCard
              key={paper.id}
              id={paper.id}
              title={paper.name}
              author={paper.author}
              time={paper.time}
              buttonTitle={"View"}
              onButtonClick={handleView}
              onClickCard={handleView}
            />
          ))}
        </Col>
      </Row>
      <Row className="d-flex justify-content-center my-5">
        <Col sm={8} lg={6} className="d-flex justify-content-center">
          <Pagination
            count={10}
            className="reviewer-pagination"
            color="primary"
            size="large"
            onChange={(event, value) => console.log(value)}
          />
        </Col>
      </Row>
    </>
  );
}

export default ReviewerAssignedPapers;
