import React, { useContext } from "react";
import Logo from "../Logo";
import { ModelContext } from "../../contexts/ModelContextProvider";
import RegisterForm from "../forms/RegisterForm";

const RegisterCard = () => {
  const { setClose } = useContext(ModelContext);
  return (
    <section className="d-md-flex r-card-container">
      <div className="col-md-6 lr-card-left-container p-5 p-md-6 d-flex flex-column justify-content-between">
          <div className="pt-5">
            <Logo
              style={{ width: "70px", height: "70px", borderRadius: "20px" }}
            />
            <h3 className="pt-4 mh-80">
              Facilitating research publishing from start to finish
            </h3>
          </div>
          <small onClick={setClose}>
            Already have an account?{" "}
            <span style={{ cursor: "pointer" }}>Login here.</span>
          </small>
        </div>

        <div className="col-md-6 p-0 p-md-5 p-md-6 d-flex align-items-center lr-card-right-container">
          <RegisterForm />
        </div>
    </section>
  );
};

export default RegisterCard;
