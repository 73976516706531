import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import axios from "axios"; // Import axios for making API calls
import { InitiativeContext } from "../../contexts/InitiativeContextProvider";

function AddNewInitiative({ show, handleClose, project_id }) {
  const [initiativeName, setInitiativeName] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { refresh} = useContext(InitiativeContext);

  const handleAddNewInitiative = async () => {
    if (!initiativeName || !description) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    console.log("Adding new initiative:", initiativeName, description);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/initiatives/`,
        {
          name: initiativeName,
          description,
          project_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data);

      if (response.data.status === 200) {
        console.log("New Initiative Added:", response.data);
        setDescription("");
        setInitiativeName("");
        handleClose();
        refresh();
      }
    } catch (error) {
      console.error("Failed to add initiative:", error);
      setErrorMessage("Failed to add initiative. Please try again.");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
        <Modal.Title style={{ textAlign: "center", marginBottom: "20px" }}>
          Create New Initiative
        </Modal.Title>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <Form style={{ width: "80%" }}>
            <Form.Group className="mb-3" controlId="initiativeName">
              <Form.Control
                type="text"
                placeholder="Initiative Name"
                autoFocus
                value={initiativeName}
                onChange={(e) => setInitiativeName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Control
                placeholder="Description"
                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{ border: 0, justifyContent: "center", marginBottom: "30px" }}
        >
          <CustomButton rounded={true} onClick={handleAddNewInitiative}>
            Add
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNewInitiative;
