// ** React Imports
import React, {useContext, useEffect, useRef, useState} from "react";

// ** Bootstrap Imports
import {Alert, Card, Stack} from "react-bootstrap";
import Logo from "../../components/Logo";
import {useNavigate, useParams} from "react-router-dom";
import {UserContext} from "../../contexts/UserContextProvider";
import {showToast} from "../../utils/toastUtils";
import {assignToMe} from "../../api/Api";

const AssignPaper = () => {
    const navigate = useNavigate();
    const { paperId } = useParams();
    const [status, setStatus] = useState('waiting');
    const hasFetched = useRef(false);
    const { isAuth } = useContext(UserContext);

    useEffect(() => {

        const token = localStorage.getItem("token");
        console.log(token)

        if (!token) {
            navigate('/reviewer/login');
            return;
        }

        let timer

        // to make sure that this api call only one time
        if (hasFetched.current) return;
        hasFetched.current = true;

        assignToMe(paperId).then((response) => {
            if (response.status === 200) {
                if (response.assigned) {
                    setStatus('assigned')
                    console.log(response.assigned)
                } else {
                    setStatus('unassigned')
                }

                timer = setTimeout(() => {
                    if (response.assigned) {
                        navigate(`/reviewer/review-paper/${paperId}`);
                    } else {
                        navigate('/reviewer/unassigned');
                    }
                }, 3000);
            }
            return () => clearTimeout(timer);

        }).catch((error) => {
            if (error.response.status === 401) {
                navigate('/reviewer/login');
                return;
            }
            console.error("An error occurred:", error);
        });

    }, []);

    return (
        <div className="bg-body-secondary min-vh-100 w-100 d-flex flex-column align-items-center pt-5">
            <Stack gap={3} className="text-center mb-5">
                <>
                    {status === 'assigned' ?
                        <h1 className="my-5 h1">Success! Your paper has been assigned.</h1> :
                        status === 'unassigned' ?
                            <h1 className="my-5 h1">Assignment Failed: This paper is already assigned to someone else.</h1> :
                            <h1 className="my-5 h1">Assigning Paper ...</h1>
                    }
                    <div className="d-inline-flex justify-content-center">
                        <Stack
                            gap={3}
                            className="shadow-sm p-md-5 p-sm-4 p-3 mx-md-0 mx-sm-5 mx-3 rounded-3 bg-white "
                            style={{ maxWidth: "700px" }}
                        >
                            <div>
                                <Logo style={{ width: "100px", height: "100px" }} />
                            </div>

                            {status === 'assigned' ?
                                <p className="px-md-5 px-2 px-sm-1 text-secondary">
                                    Success! This paper has been assigned to you. <br/>
                                    Redirecting you to the paper review page
                                </p> :
                                status === 'unassigned' ?
                                    <p className="px-md-5 px-2 px-sm-1 text-secondary">
                                        This paper has already been assigned to another reviewer. <br/>
                                        Redirecting you to the unassigned paper page
                                    </p> :
                                    <p className="px-md-5 px-2 px-sm-1 text-secondary">
                                        Please hold tight! This should just take a moment.
                                    </p>
                            }
                        </Stack>
                    </div>
                </>
            </Stack>
        </div>
    )
}

export default AssignPaper
