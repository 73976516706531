import React, { createContext } from "react";
import useFetch from "../hooks/useFetch";

const InitiativeContext = createContext(null);

function InitiativeContextProvider({ children, projectId }) {
  const { data, error, loading, refresh } = useFetch(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}`
  );

  const value = {
    data,
    error,
    loading,
    refresh,
  };

  return (
    <InitiativeContext.Provider value={value}>
      {children}
    </InitiativeContext.Provider>
  );
}

export { InitiativeContext };
export default InitiativeContextProvider;
