import { createContext, useState } from "react";

export const ModelContext = createContext(null);

const ModelContextProvider = ({ children }) => {
    const [isOpen, setModalOpen] = useState(false);
    const [isOpenVerifyAlert, setOpenVerifyAlert] = useState(false);

  const VerifyAlertOpen = () => setOpenVerifyAlert(true);
  const VerifyAlertClose = () => setOpenVerifyAlert(false);

  const setOpen = (email,password) => setModalOpen(true);
  const setClose = () => setModalOpen(false);

  const value = {
    isOpen,
    setOpen,
    setClose,
    isOpenVerifyAlert,
    VerifyAlertOpen,
    VerifyAlertClose,
  };
  return (
    <ModelContext.Provider value={value}>{children}</ModelContext.Provider>
  );
};
export default ModelContextProvider;
