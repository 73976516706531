import React from "react";
import LoginCard from "./cards/LoginCard";
import ModelContextProvider from "../contexts/ModelContextProvider";

const Login = () => {
  return (
    <ModelContextProvider>
      <LoginCard />
    </ModelContextProvider>
  );
};

export default Login;
