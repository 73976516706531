import React from 'react'
import UserContextProvider from '../contexts/UserContextProvider'
import UserProfileComponent from '../components/UserProfileComponent'

function UserProfile() {
  return (
    <UserContextProvider>
        <UserProfileComponent />
    </UserContextProvider>
  )
}

export default UserProfile