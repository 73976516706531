import {Bounce, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastConfig = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
};

export const showToast = (message, type = 'default') => {
    switch (type) {
        case 'success':
            toast.success(message, toastConfig);
            break;
        case 'error':
            toast.error(message, toastConfig);
            break;
        case 'warning':
            toast.warn(message, toastConfig);
            break;
        case 'info':
            toast.info(message, toastConfig);
            break;
        default:
            toast(message, toastConfig);
            break;
    }
};
