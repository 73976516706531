import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../components/CustomButton";
import { Autocomplete, TextField } from "@mui/material";
import { useFormik } from "formik";
import {
  getCountries,
  getInstitutes,
  submitExtraUserDetails,
} from "../api/Api";
import { UserContext } from "../contexts/UserContextProvider";
import { useNavigate } from "react-router-dom";
import {showToast} from "../utils/toastUtils";
import {UserDetailsSchema} from "../utils/validations";

const experience = [
  { duration: "0 years" },
  { duration: "1-2 years" },
  { duration: "3-5 years" },
  { duration: "6-10 years" },
  { duration: "11-15 years" },
  { duration: "16+ years" },
  { duration: "Varies" },
];

function UserExtraDetails() {
  const [institutes, setInstitutes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState("");
  const [customInstitute, setCustomInstitute] = useState(false);
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      username: "",
      country: "",
      jobTitle: "",
      company: "",
      experience: "",
      institute_id: "",
      institute_name: "",
      website: "",
      aboutYou: "",
    },
    validate: (values) => {
      const errors = {};
      const requiredFields = [
        "username",
        "country",
        "jobTitle",
        "company",
        "experience",
        "institute_id",
        "website",
        "aboutYou",
      ];

      if (customInstitute) {
        requiredFields.push("institute_name");
        let exists = institutes.some(
          (obj) => obj.name.toLowerCase() === values.institute_name.toLowerCase()
        );

        if (exists) errors["institute_name"] = "Institute Already Exists";
      }

      requiredFields.forEach((field) => {
        if (!values[field]) errors[field] = "Required";
      });

      return errors;
    },
    onSubmit: async (values) => {
      /*Create the object with common fields and conditionally include either
      the custom institute fields or the regular institute field*/
      let obj = {
        username: values.username,
        country_code: values.country.code,
        jobTitle: values.jobTitle,
        company: values.company,
        experience: values.experience,
        website: values.website,
        aboutYou: values.aboutYou,
        ...(customInstitute
          ? { institute_id: null, institute_name: values.institute_name }
          : { institute_id: values.institute_id }),
      };

      // Handle form submission logic here
      const response = await submitExtraUserDetails(obj);
      console.log("API response:", response);
      if (response.status === 200) {
        navigate("/home");
      } else if (response.message === "Username already exists.") {
        showToast("Username already exists.", 'warning');
        navigate("/home");
      }
    },
    validationSchema:UserDetailsSchema,
  });

  useEffect(() => {
    // load institutes
    getInstitutes().then((response) => {
      console.log(response);
      if (response.status === 200) {
        setInstitutes([
          ...response.data,
          { id: -1, name: "Other (Please Enter your institute below)" },
        ]);
        return;
      }
      setError(response.message);
    });

    // load countries
    getCountries().then((response) => {
      console.log(response);
      if (response.status === 200) {
        setCountries(response.data);
        return;
      }
      setError(response.message);
    });
  }, []);

  const handleInstituteDropdownOnSelect = (event, value) => {
    if (value && value.id === -1) {
      setCustomInstitute(true);
    } else {
      setCustomInstitute(false);
    }

    formik.setFieldValue("institute_id", value ? value.id : null);
  };

  const fname = userInfo?.name.split(" ")[0];

  if (error)
    return (
      <p className="d-flex justify-content-center m-4 alert alert-danger">
        {error}
      </p>
    );

  return (
    <div
      id="user-extra-details"
      className="container-fluid my-5 px-md-0 px-4"
      style={{ maxWidth: "400px" }}
    >
      <div className="text-center mb-4">
        <h2>👋 Hello {fname},</h2>
        <p className="text-color-secondary">
          Just a few more steps to complete your profile
        </p>
      </div>

      <form id="user-extra-details-form" onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <label htmlFor="username">Username</label>
            <TextField
              id="username"
              placeholder="Enter your username"
              {...formik.getFieldProps("username")}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="country">Country</label>
            <Autocomplete
              disablePortal
              options={countries}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
              )}
              onChange={(event, value) =>
                formik.setFieldValue("country", value)
              }
            />
          </Col>
          <Col>
            <label htmlFor="jobTitle">Job title</label>
            <TextField
              id="jobTitle"
              placeholder="Enter your job title"
              {...formik.getFieldProps("jobTitle")}
              error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
              helperText={formik.touched.jobTitle && formik.errors.jobTitle}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="company">Company Name</label>
            <TextField
              id="company"
              placeholder="Select your company"
              {...formik.getFieldProps("company")}
              error={formik.touched.company && Boolean(formik.errors.company)}
              helperText={formik.touched.company && formik.errors.company}
            />
          </Col>
          <Col>
            <label htmlFor="experience">Experience</label>
            <Autocomplete
              disablePortal
              options={experience.map((item) => item.duration)} // Replace with actual experience options
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.experience &&
                    Boolean(formik.errors.experience)
                  }
                  helperText={
                    formik.touched.experience && formik.errors.experience
                  }
                />
              )}
              onChange={(event, value) =>
                formik.setFieldValue("experience", value)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="institute_id">Institute</label>
            <Autocomplete
              disablePortal
              // options={institutes.map((institute) => institute.name)} // Replace with actual institute options
              options={institutes}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    formik.touched.institute_id &&
                    Boolean(formik.errors.institute_id)
                  }
                  helperText={
                    formik.touched.institute_id && formik.errors.institute_id
                  }
                />
              )}
              onChange={handleInstituteDropdownOnSelect}
            />
          </Col>
        </Row>
        <Row className={`${customInstitute ? "" : "d-none"}`}>
          <Col>
            <label htmlFor="institute_name">institute name</label>
            <TextField
              id="institute_name"
              placeholder="Enter your institute name"
              {...formik.getFieldProps("institute_name")}
              error={
                formik.touched.institute_name &&
                Boolean(formik.errors.institute_name)
              }
              helperText={
                formik.touched.institute_name && formik.errors.institute_name
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="website">Website</label>
            <TextField
              id="website"
              placeholder="Enter your website"
              {...formik.getFieldProps("website")}
              error={formik.touched.website && Boolean(formik.errors.website)}
              helperText={formik.touched.website && formik.errors.website}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor="aboutYou">About you</label>
            <TextField
              id="aboutYou"
              placeholder="Type here"
              {...formik.getFieldProps("aboutYou")}
              error={formik.touched.aboutYou && Boolean(formik.errors.aboutYou)}
              helperText={formik.touched.aboutYou && formik.errors.aboutYou}
            />
          </Col>
          <CustomButton className="w-100 mt-4" type="submit">
            Next
          </CustomButton>
        </Row>
      </form>
    </div>
  );
}

export default UserExtraDetails;
