import React from 'react'
import DialogBox from './DialogBox';

const DeleteModel = ({ open, closeAlert, onClick, type }) => {
    return (
      <DialogBox
        open={open}
        setClose={closeAlert}
        bodyText={
          <span>
            Once deleted, this{" "}
            <strong>{type} will be permanently removed </strong> and cannot be
            recovered. Are you sure you want to delete?
          </span>
        }
        submitButtonTitle="Delete"
        submitButtonVariant="btn-danger"
        onClick={onClick}
      />
    );
  };

export default DeleteModel