import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SnackbarProvider, useSnackbar } from "notistack";
import CheckIcon from "@mui/icons-material/Check";
import "../../styles/reviewer/ReviewerDashboard.css";
import ReviewerCard from "../../components/cards/ReviewerCard";
import { Pagination } from "@mui/material";
import { assignToMe, getReviewerUnAssignedPapers } from "../../api/Api";

function PreReviewerDashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle paper assignment
  const handleAssign = (id) => {
    console.log(id)
    assignToMe(id).then((response) => {
      console.log(response);
      if (response.status === 200) {
        enqueueSnackbar(`Assigned to yourself`, { variant: "success" });
        setPapers(papers.filter((paper) => paper.id !== id));
      } else console.error("Error assigning paper:", response.message);
    }).catch((error) => console.error("Error assigning paper:", error));
  };

  useEffect(() => {
    // Fetch unassigned papers
    setLoading(true);
    getReviewerUnAssignedPapers().then((response) => {
      console.log(response);
      if (response.status === 200) setPapers(response.data);
      else setError(response.message);
    }).catch((error) => {
      console.error("Error fetching data:", error);
      setError(error.message);
    })
    setLoading(false);
  }, []);

  // console.log(papers);
  if (loading) return <p className="d-flex justify-content-center my-4">Loading...</p>;
  if (error) return <p className="d-flex justify-content-center m-4 alert alert-danger">{error}</p>;
  return (
    <>
      <Row className="d-flex justify-content-center my-4">
        <Col sm={10} md={8} lg={6} className="ps-0">
          {papers.map((paper) => (
            <ReviewerCard
              key={paper.id}
              id={paper.id}
              title={paper.name}
              author={paper.author}
              time={paper.time}
              buttonTitle={"Assign"}
              onButtonClick={handleAssign}
              onClickCard={handleAssign}
            />
          ))}
        </Col>
      </Row>
      <Row className="d-flex justify-content-center my-5">
        <Col sm={8} lg={6} className="d-flex justify-content-center">
          <Pagination
            count={10}
            className="reviewer-pagination"
            color="primary"
            size="large"
            onChange={(event, value) => console.log(value)}
          />
        </Col>
      </Row>
    </>
  );
}

// Wrapping the component with SnackbarProvider to enable snackbars
function ReviewerUnAssignedPapers() {
  return (
    <div id="snackbar-provider">
      <SnackbarProvider
        className="px-3 py-0 w-100"
        iconVariant={{
          success: <CheckIcon />,
        }}
        style={{ backgroundColor: "#047857", maxWidth: "400px" }}
        maxSnack={1}
      >
        <PreReviewerDashboard />
      </SnackbarProvider>
    </div>
  );
}

export default ReviewerUnAssignedPapers;
