import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Home from "./pages/Home";
import "./styles/Custom.css";
import "./App.css";
import Project from "./pages/Project";
import UserContextProvider from "./contexts/UserContextProvider";
import LoginPage from "./pages/LoginPage";
import Initiative from "./pages/Initiative";
import Preview from "./pages/Preview";
import InitiativeContextProvider from "./contexts/InitiativeContextProvider";
import NotFound from "./pages/NotFound";
import VerifyEmail from "./pages/VerifyEmail";
import ReviewerLogin from "./pages/reviewer/ReviewerLogin";
import ReviewerLayout from "./layout/ReviewerLayout";
import ReviewerUnAssignedPapers from "./pages/reviewer/ReviewerUnAssignedPapers";
import ReviewerAssignedPapers from "./pages/reviewer/ReviewerAssignedPapers";
import ReviewerPaperReview from "./pages/reviewer/ReviewerPaperReview";
import UserExtraDetails from "./pages/UserExtraDetails";
import SupplementaryMaterials from "./pages/SupplementaryMaterials";
import Notifications from "./pages/Notifications";
import UserProfile from "./pages/UserProfile";
import SecondaryLayout from "./layout/SecondaryLayout";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AssignPaper from "./pages/reviewer/AssignPaper";

function InitiativeWrapper({ children }) {
  const { projectId } = useParams();
  return (
    <InitiativeContextProvider projectId={projectId}>
      {children}
    </InitiativeContextProvider>
  );
}

const App = () => {
  return (
    <UserContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/verifyEmail" element={<VerifyEmail />} />
          <Route path="/extra/details" element={<UserExtraDetails />} />

          {/*Main layout contains upper navigation bar and the left side bar*/}
          <Route element={<MainLayout />}>
            <Route index path="/home" element={<Home />} />
            <Route
              path="/project/:projectId"
              element={
                <InitiativeWrapper>
                  <Project />
                </InitiativeWrapper>
              }
            />
            <Route
              path="/project/:projectId/initiative/:initiativeId"
              element={
                <InitiativeWrapper>
                  <Initiative />
                </InitiativeWrapper>
              }
            />
            <Route
              path="/paper/:paperId"
              element={<SupplementaryMaterials />}
            />
            <Route path="/user/notification" element={<Notifications />} />
          </Route>

          {/* layout with upper navigation */}
          <Route element={<SecondaryLayout />}>
            <Route
              path="/project/:projectId/initiative/:initiativeId/preview/:paperId"
              element={<Preview />}
            />
            <Route path="/userProfile" element={<UserProfile />} />
          </Route>

          {/* reviewer Api's */}
          <Route path="/reviewer/login" element={<ReviewerLogin />} />
          <Route path="/reviewer/assign/:paperId" element={<AssignPaper />} />
          <Route element={<ReviewerLayout />}>
            <Route
              path="/reviewer/unassigned"
              element={<ReviewerUnAssignedPapers />}
            />
            <Route
              path="/reviewer/assigned"
              element={<ReviewerAssignedPapers />}
            />
            <Route
              path="/reviewer/review-paper/:paperId"
              element={<ReviewerPaperReview />}
            />
          </Route>

          {/* 404 Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>

      <div>
        <ToastContainer />
      </div>
    </UserContextProvider>
  );
};

export default App;
