import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ReviewerNavBar from "../components/reviewer/ReviewerNavBar";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import PeopleIcon from "@mui/icons-material/People";
import "../styles/reviewer/ReviewerNavBar.css";
import ReviewerSidebar from "../components/reviewer/ReviewerSidebar";

const ReviewerLayout = () => {

  return (
    <>
      <ReviewerNavBar/>
      <Row style={{ minHeight: "100vh" }} className="p-0 m-0">
        <Col className="d-none d-md-flex" sm={4} lg={3} style={{maxWidth:'300px'}}>
          <aside>
            <ReviewerSidebar />
          </aside>
        </Col>
        <Col md className="border-start p-0 m-0 px-3">
          <main>
            <Outlet />
          </main>
        </Col>
      </Row>
    </>
  );
};

export default ReviewerLayout;
