import React from "react";
import NavigationBar from "../components/NavigationBar";
import { Outlet } from "react-router-dom";
import ProjectContextProvider from "../contexts/ProjectContextProvider";

// layout with upper navigation
function SecondaryLayout() {
  return (
    <ProjectContextProvider>
      <NavigationBar />
      <div style={{ minHeight: "80vh" }}>
        <Outlet />
      </div>
    </ProjectContextProvider>
  );
}

export default SecondaryLayout;
