import * as React from "react";
import { Modal } from "react-bootstrap";
import "../../styles/reviewer/Components.css";
import CustomButton from "../CustomButton";

function CheckYourInbox({ open, setClose, onClick ,email}) {
  return (
    <Modal
      style={{ top: "10%" }}
      className="dialog-box text-center font-style-primary"
      show={open}
      onHide={setClose}
    >
      <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
      <Modal.Title>
          <strong className="fs-4">Verify Your Email</strong>
        </Modal.Title>
      <Modal.Body>
        <p className="mt-4 p-sm-3  text-secondary">
          Thank you for signing up! We’ve sent a verification email to {" "}
          <span className="fw-bold text-black">{email}</span>. Please check your inbox and click the
          verification link to activate your account.{" "}
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-0 mb-3">
        <CustomButton className={"m-0 px-5"} onClick={onClick}>Ok</CustomButton>
      </Modal.Footer>
    </Modal>
  );
}

export default CheckYourInbox;
