import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Stack } from "react-bootstrap"; // Assuming you're using React-Bootstrap
import CustomButton from "../components/CustomButton";
import Logo from "../components/Logo";
import Footer from "../components/Footer";

function VerifyEmail() {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const status = query.get("status");
  const message = query.get("message");

  return (
    <div
      className="py-5 position-relative  bg-custom-gray"
      style={{ height: "100vh" }}
    >
      <Stack gap={3} className="text-center">
        <>
          {status === "200" ? (
            <>
              <h1 className="my-5">Email Verified Successfully!</h1>
              <div className="d-inline-flex justify-content-center">
                <Stack
                  gap={3}
                  className="shadow-sm p-md-5 p-sm-4 p-3 mx-md-0 mx-sm-5 mx-3 rounded-3 bg-white "
                  style={{ maxWidth: "700px" }}
                >
                  <div>
                    <Logo style={{ width: "100px", height: "100px" }} />
                  </div>
                  <p className="px-md-5 px-2 px-sm-1 text-secondary">
                    Your email has been successfully verified. Please login
                    again to access the website.
                  </p>
                  <div>
                    <CustomButton onClick={()=> navigate('/')} variant="primary" rounded>
                      Log in to your account
                    </CustomButton>
                  </div>
                </Stack>
              </div>
            </>
          ) : (
            <Alert
              className="d-inline-flex m-auto"
              style={{ maxWidth: "300px" }}
              variant="danger"
            >
              <h3>Verification Failed</h3>
              <p>{message}</p>
            </Alert>
          )}
          <section className="position-absolute bottom-0 start-0 w-100">
            <Footer className="border-top-0" />
          </section>
        </>
      </Stack>
    </div>
  );
}

export default VerifyEmail;