import React from "react";
import CustomSkeleton from "../CustomSkeleton";

export default function PaperCardSkeleton ({ n }) {
    const array = Array.from({ length: n }, (_, i) => i);
    return (
      <>
        {array.map((i) => (
          <div className="border-bottom border-color-secondary mt-3">
            <CustomSkeleton className="w-25 py-2 mb-2" />
            <CustomSkeleton className=" mb-1" count={3}/>
          </div>
        ))}
      </>
    );
};
