import React from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";

function Logo({ className, style }) {
  const navigate = useNavigate();

  return (
    <Image
      style={{ cursor: "pointer",borderRadius:"15px", ...style }}
      className={className}
      width={40}
      height={40}
      onClick={() => navigate("/home")}
      src={logo}
    />
  );
}

export default Logo;
