import React, { useContext } from "react";
import projectsIcon from "../assets/solar_box-broken.png";
import projectIcon from "../assets/octicon_project-24.png";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../contexts/ProjectContextProvider";
import { GoBell } from "react-icons/go";
import CustomSkeleton from "./CustomSkeleton";

function SideMenu() {
  const navigate = useNavigate();

  return (
    <section id="side-menu" className="py-3 px-4 p-md-5">
      <div>
        <ProjectMenu />
        <h4
          style={{ cursor: "pointer", fontWeight: "normal" }}
          className="py-4 d-flex align-items-center column-gap-1"
          onClick={() => navigate("/user/notification")}
        >
          <GoBell className="text-color-cyan" size={22}/> Notifications
        </h4>
      </div>
    </section>
  );
}

export default SideMenu;

const ProjectMenu = () => {
  const navigate = useNavigate();
  const { data: projectArray, error, loading } = useContext(ProjectContext);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <h4
        style={{ cursor: "pointer", fontWeight: "normal" }}
        className="pb-2"
        onClick={() => navigate("/home")}
      >
        <img width={20} src={projectsIcon} alt="project-icon" /> Projects
      </h4>
      {!loading ? (
        projectArray?.map((project) => (
          <div
            onClick={() => navigate(`project/${project?.id}`)}
            className="ps-3 ps-lg-4"
            style={{ cursor: "pointer" }}
            key={project?.id} // Use project.id as the key for better uniqueness
          >
            <h6 className="text-nowrap text-truncate">
              <img width={17} src={projectIcon} alt="project-icon" />{" "}
              {project.name}
            </h6>
          </div>
        ))
      ) : (
        <CustomSkeleton className="ms-4 py-1 my-2" count={5} />
      )}
      {projectArray && projectArray.length === 0 && <div className="ps-5 text-secondary">No projects</div>}

    </>
  );
};
