import React from "react";

const DarkButton = ({
  type = "button",
  title,
  icon,
  className = "",
  style,
  onClick,
  variant = "btn-dark", // bootstrap button classes
}) => {
  return (
    <button
      className={`btn ${variant} rounded-1 px-4 d-flex align-items-center gap-2 my-2 ${className}`}
      style={{ paddingBlock: "10px", ...style }}
      type={type}
      onClick={onClick}
    >
      {icon}
      {/* Icon passed here */}
      <strong>{title}</strong>
    </button>
  );
}

export default DarkButton;
