import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

const FadeMenu = ({ buttonLabel = "Menu", menuItems = [], onMenuItemClick = () => {} ,value ,className}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
    onMenuItemClick(item,value);
  };

  return (
    <div className={className}>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        style={{color:"#000" , backgroundColor:"#fff",padding:0,margin:0,fontSize:"22px"}}
        onClick={handleClick}
      >
        {buttonLabel}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
        TransitionComponent={Fade}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={() => handleClose(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FadeMenu;
