import {useNavigate} from "react-router-dom";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import PeopleIcon from "@mui/icons-material/People";
import React from "react";

const ReviewerSidebar = () => {
    const navigate = useNavigate();
    const handleUnassigned = () => {
        navigate("/reviewer/unassigned");
    }
    const handleAssigned = () => {
        navigate("/reviewer/assigned");
    }

    return (
        <div id="reviewer-sidebar" className="container p-3 py-md-4 my-3 my-md-4">
            <ul>
                <li onClick={handleUnassigned}>
                    <ScreenShareIcon /> Unassigned
                </li>
                <li onClick={handleAssigned}>
                    <PeopleIcon /> Assigned
                </li>
            </ul>
        </div>
    );
};

export default ReviewerSidebar