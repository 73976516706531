import React from "react";
import { Card, Form, Stack } from "react-bootstrap";
import "../../styles/Home.css";

function CheckBoxForm({
  Issues,
  checkedIssues,
  handleCheckChange,
  style = {
    maxWidth: "800px",
    margin: "auto",
    textAlign: "start",
  },
}) 
{
  const toggleCheckbox = (issueId) => {
    handleCheckChange(issueId, !checkedIssues[issueId]);
  };

  return (
    <Form id="preview-checkbox-form" style={style}>
      {Issues &&
        Issues.map((issue) => (
          <Stack
            key={issue.id}
            direction="horizontal"
            gap={2}
            className="border-bottom"
          >
            <Card className="my-2 border-0 rounded-0 w-100">
              <Card.Body>
                <Card.Title onClick={() => toggleCheckbox(issue.id)} style={{ fontSize: "18px",cursor:"pointer" }}>
                  {issue.name || issue.title}
                </Card.Title>
                <Card.Text style={{ fontSize: "14px" }}>
                  {issue.description}
                </Card.Text>
              </Card.Body>
            </Card>
            <Form.Check
              type="checkbox"
              checked={checkedIssues[issue.id] || false}
              onChange={(e) => handleCheckChange(issue.id, e.target.checked)}
              aria-label={`Checkbox for ${issue.name}`}
            />
          </Stack>
        ))}
    </Form>
  );
}

export default CheckBoxForm;
