import { createContext } from "react";
import useFetch from "../hooks/useFetch";

const ProjectContext = createContext(null);

function ProjectContextProvider({ children }) {
  const { data, error, loading, refresh } = useFetch(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/`
  );

  const value = {
    data,
    error,
    loading,
    refresh,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
}

export { ProjectContext };
export default ProjectContextProvider;
