import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SendIcon from "@mui/icons-material/Send";
import { TfiComments } from "react-icons/tfi";
import DialogBox from "../../components/modals/DialogBox";
import DarkButton from "../../components/DarkButton";
import {
  getReviewPaper,
  getReviewQuestions,
  submitReviewPaper,
} from "../../api/Api";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineGroup } from "react-icons/ai";
import ReferencePreviewModel from "../../components/modals/ReferencePreviewModel";
import { showToast } from "../../utils/toastUtils";
import "../../styles/reviewer/ReviewerPages.css";
import { Stack } from "react-bootstrap";
import CircularIndeterminate from "../../components/CircularIndeterminate";
import {Tooltip} from "@mui/material";

function ReviewerPaperReview() {
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [paper, setPaper] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState([]);
  const [references, setReferences] = useState("");
  const [formattedReferences, setFormattedReferences] = useState([]);
  const [showReferences, setShowReferences] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { paperId } = useParams();
  const navigate = useNavigate();
  // State to control the visibility of comment textareas
  const [showComments, setShowComments] = useState({});

  const handleToggleComment = (inputId) => {
    setShowComments((prev) => ({
      ...prev,
      [inputId]: !prev[inputId],
    }));
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Initialize Formik
  const formik = useFormik({
    initialValues: inputs.reduce((acc, input) => {
      acc[input.input_id] = input.value || ""; // Ensure all initial values are defined
      acc[`${input.input_id}_comment`] = "ihf"; // Comment field
      return acc;
    }, {}),
    validate: (values) => {
      console.log("Form values: ", values);
      const errors = {};
      inputs.forEach((input) => {
        if (!values[input.input_id]) {
          if (input.type === "boolean") {
            values[input.input_id] = false;
            return;
          }
          errors[input.input_id] = "Required";
        }
      });
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      console.log("Form values: ", values);
      console.log("Inputs", inputs);

      const submitData = {
        inputs: inputs.map((input) => ({
          input_id: input.input_id,
          value: values[input.input_id], // Fetch the value from Formik
          comment: values[`${input.input_id}_comment`]?? null, // Fetch the comment value
        })),
      };

      let references = formattedReferences.map((ref, index) => ({
        citation: `${index + 1}. ${ref}`,
      }));

      setPayload([submitData, { references }]);

      handleClickOpen(); // Call the function to handle opening a dialog or modal
      setSubmitting(false); // Reset submitting state
    },
  });

  useEffect(() => {
    setLoading(true);
    // -------------Fetch paper----------------
    getReviewPaper(paperId)
      .then((response) => {
        response.status === 200
          ? setPaper(response.data)
          : setError(response.message);
      })
      .catch((err) => setError("Failed to fetch review paper."));

    // ---------------Fetch review questions--------------
    getReviewQuestions(paperId)
      .then((response) => {
        response.status === 200
          ? setInputs(response.data)
          : setError(response.message);
      })
      .catch((err) => setError("Failed to fetch review questions."))
      .finally(() => setLoading(false));
  }, [paperId]);

  // Handle form submission with validation check
  const handleSubmitWithValidationCheck = async (e) => {
    e.preventDefault(); // Prevent default form submission
    await Object.keys(formik.errors).forEach((key) =>
      formik.setFieldTouched(key, true)
    );
    const errors = await formik.validateForm(); // Validate the form
    if (Object.keys(errors).length > 0) return; // Check if there are any validation errors
    formik.handleSubmit(); // Call Formik's handleSubmit if no errors
  };

  // Handle form submission
  const handleServerSubmit = async () => {
    if (!isLoading) {
      setIsLoading(true)
      console.log("Submitted data: ", payload);
      const response = await submitReviewPaper(paper.id, payload);
      if (response) {

        if (response?.status === 200 || response?.status === 202) {
          showToast(response.message, "success");
          formik.resetForm()
        } else {
          console.error("Error submitting review:", response.message);
        }
      }
      handleClose();
      navigate("/reviewer/assigned");
    }
  };

  const handleReferenceInputChange = (event) => {
    const pastedText = event.target.value;
    setReferences(pastedText);

    if (references.trim() === "") {
      setShowError(true);
    }
    setShowError(false);
    // Split the pasted references by line breaks
    const splitReferences = pastedText
      .split("\n")
      .filter((ref) => ref.trim() !== "");
    setFormattedReferences(splitReferences);

    console.log("pasted Text", pastedText);
    console.log("Splited refernce", splitReferences);
  };

  const handleReferenceInputBlur = () => {
    if (references.trim() === "") {
      setShowError(true);
    }
  };

  const handleReferenceInputFocus = () => {
    setShowError(false);
  };

  // Render input fields based on type
  const renderInputField = (input) => {
    switch (input.type) {
      case "dropdown":
        return (
          <select
            id={input.input_id}
            name={input.input_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values[input.input_id]}
            className="border p-2 review-paper-input"
          >
            {input.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "boolean":
        return (
          <div className="py-3 d-flex flex-row-reverse">
            <input
              type="checkbox"
              name={input.input_id}
              className="border p-2"
              onChange={(e) =>
                formik.setFieldValue(input.input_id, e.target.checked)
              }
              checked={formik.values[input.input_id] || false}
              onBlur={formik.handleBlur}
            />
          </div>
        );
      case "numeric":
        return (
          <input
            type="number"
            name={input.input_id}
            className="border p-2 review-paper-input"
            placeholder="Enter the answer"
            onChange={formik.handleChange}
            value={formik.values[input.input_id]}
            onBlur={formik.handleBlur}
          />
        );
      case "shorttext":
        return (
          <input
            type="text"
            name={input.input_id}
            className="border p-2 review-paper-input"
            placeholder="Enter the answer"
            onChange={formik.handleChange}
            value={formik.values[input.input_id]}
            onBlur={formik.handleBlur}
          />
        );
      case "longtext":
        return (
          <textarea
            name={input.input_id}
            className="border p-2 w-100"
            placeholder="Enter the answer"
            onChange={formik.handleChange}
            value={formik.values[input.input_id]}
            onBlur={formik.handleBlur}
          />
        );
      default:
        return null;
    }
  };

  if (loading)
    return <CircularIndeterminate />;
  if (error)
    return (
      <p className="d-flex justify-content-center m-4 alert alert-danger">
        {error}
      </p>
    );
  return (
    <div className="reviewer-paper-review p-lg-5 p-2 mx-lg-3 mx-md-2 mx-1">
      <h1 className="title">
        <strong>{paper?.name}</strong>
      </h1>
      <hr className="hr" />
      <DarkButton
        className="my-5"
        title="Download File"
        icon={<KeyboardArrowRightIcon />}
        onClick={() => {
          window.open(`${process.env.REACT_APP_BACKEND_BASE_URL}/media/papers/${paper?.file_id}`);
        }}
        variant="btn-outline-dark"
      />

      <h5 className="title mt-5 mb-4">
        <strong>Inputs</strong>
      </h5>

      <form
        className="my-5"
        style={{ maxWidth: "800px" }}
        onSubmit={handleSubmitWithValidationCheck}
      >
        {inputs.map((input) => (
          <div key={input.input_id} className="mb-4">
            <Stack direction="horizontal" className="mb-2 align-items-start" gap={3}>
              {/* question */}
              <p className="review-paper-question-text">{input.question}</p>
              <div className="ms-auto d-flex flex-column">
                {/* input field */}
                {renderInputField(input)}

                {/* error message */}
                {formik.touched[input.input_id] &&
                formik.errors[input.input_id] ? (
                  <h6 className="text-danger float-end">
                    {formik.errors[input.input_id]}
                  </h6>
                ) : null}
              </div>

              {/* add comment icon */}
              <div
                className="p-2 bg-body-secondary opacity-50 rounded-1"
                onClick={() => handleToggleComment(input.input_id)}
              >
                <TfiComments size={20} />
              </div>

            </Stack>

            {/* Render comment field */}
            {showComments[input.input_id] && (
              <div className="pe-5 me-1 mb-3">
              <input
                name={`${input.input_id}_comment`}
                className="border p-2 w-100 text-secondary"
                placeholder="Enter the comment"
                onChange={formik.handleChange}
                value={formik.values[`${input.input_id}_comment`] || ""}
                onBlur={formik.handleBlur}
              />
              </div>
            )}
          </div>
        ))}

        <div className="pe-5 me-2">
          <div className="d-flex justify-content-between mb-3">
            <span>Paper references</span>

            <Tooltip title={"Formatted References"} placement="top" disableFocusListener arrow>
              <span className={`${formattedReferences.length === 0 ? '' : 'd-none'}`}></span>
              <button
                  type={'button'}
                  disabled={formattedReferences.length === 0}
                  onClick={()=>setShowReferences(true)}
                  className={`border border-black rounded-1 p-1 m-0 d-flex align-items-center justify-content-center 
                  ${formattedReferences.length === 0 ? 'disabled opacity-75' : ''}`}
                  style={{height:'max-content'}}>
                <AiOutlineGroup style={{color:'#333'}} className="p-0 m-0" />
              </button>
            </Tooltip>
          </div>
          <textarea
            value={references}
            onChange={handleReferenceInputChange}
            onFocus={handleReferenceInputFocus}
            onBlur={handleReferenceInputBlur}
            placeholder="Paste all the references here..."
            className="w-100 border p-2"
            rows="5"
          />
          <h6 className={`${showError ? "" : "d-none"} text-danger`}>
            Required
          </h6>
        </div>

        <div className="d-flex justify-content-center">
          <DarkButton
            title="Submit"
            icon={<SendIcon fontSize="22px" />}
            type="submit"
          />
        </div>
      </form>

      {/* Dialog to confirm submission */}
      <DialogBox
        open={open}
        loading={isLoading}
        setClose={handleClose}
        onClick={handleServerSubmit}
      />

      <ReferencePreviewModel
        open={showReferences}
        setClose={() => setShowReferences(false)}
        references={formattedReferences}
      />
    </div>
  );
}

export default ReviewerPaperReview;
