import React from 'react'
import CustomSkeleton from '../CustomSkeleton';

function InitiativeCardSkeleton({n}) {
    const array = Array.from({ length: n }, (_, i) => i);
    return (
      <>
        {array.map((i) => (
          <div className="border-bottom border-color-secondary mt-3">
            <CustomSkeleton className="p-5 m-0" count={2} />
            <CustomSkeleton className="my-1" count={3}/>
            <CustomSkeleton className="w-25"/>
          </div>
        ))}
      </>
    );
}

export default InitiativeCardSkeleton