import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Dropzone from "dropzone";
import {showToast} from "../../utils/toastUtils";

function UploadDropzone({ show, handleClose, initiativeId, projectId }) {
  const navigate = useNavigate();
  const dropzoneRef = useRef(null);  // Ref for the Dropzone element
  const [uploadData, setUploadData] = useState(null);
  const [paperId, setPaperId] = useState(null);
  const dropzoneInstance = useRef(null); // Ref for the Dropzone instance

  const initializeDropzone = () => {
    if (dropzoneRef.current && !dropzoneInstance.current) {
      dropzoneInstance.current = new Dropzone(dropzoneRef.current, {
        url: `${process.env.REACT_APP_BACKEND_BASE_URL}/papers/upload/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Cache-Control': '',
        },
        acceptedFiles: "application/pdf",
        addRemoveLinks: true,
        maxFiles: 1,  // Limit to 1 file
        dictDefaultMessage: "Drag here",
        dictFallbackMessage: "Your browser does not support drag'n'drop file uploads.",
        init: function () {
          this.on("addedfile", function (file) {
            console.log("File added:", file);
          });
          this.on("success", function (file, response) {
            console.log("File uploaded successfully:", response);
            setUploadData(response.data); 
            disableDropzone();
          });
          this.on("error", function (file, message) {
            file.previewElement.querySelector(".dz-error-message").textContent = "igdsidis";
            console.log("Failed to upload file:", message);
            disableDropzone();
          });
          this.on("removedfile", function (file) {
            console.log("File removed:", file);
            enableDropzone(); // Enable Dropzone to accept new file after removal
          });
        },
      });
    }
  };
  
  const disableDropzone = () => {
    if (dropzoneInstance.current) {
      dropzoneInstance.current.options.clickable = false;  // Disable the clickable option
      dropzoneInstance.current.disable(); // Disable Dropzone entirely
    }
  };
  
  const enableDropzone = () => {
    if (dropzoneInstance.current) {
      dropzoneInstance.current.options.clickable = true;  // Re-enable clickable option
      dropzoneInstance.current.enable(); // Enable Dropzone
    }
  };


  const handleUpload = async () => {
    try {
      if (!uploadData) {
        console.error("Please upload a file before proceeding.");
        return;
      }

      const payload = {
        name: uploadData.file_name,
        description: "Description of the uploaded paper.",
        initiative_id: initiativeId,
        file_id: uploadData.file_hash,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/papers/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.status === 200) {
        const paperId = response.data.data.id;
        setPaperId(paperId);
        navigate(
          `/project/${projectId}/initiative/${initiativeId}/preview/${paperId}?upload=true`
        );
      } else {
        showToast(response.data.message, 'error');
        console.error("An error occurred while uploading the file. Please try again.");
      }
    } catch (error) {
      showToast(error.response?.data?.message || "Error uploading data", "error");
      console.error("Upload failed:", error);
    }
  };

  useEffect(() => {
    if (dropzoneInstance.current) {
      dropzoneInstance.current.options.clickable === false ? enableDropzone() : initializeDropzone();
    } else {
      initializeDropzone();
    }

    if (!show) setUploadData(null);

    return () => {
      if (dropzoneInstance.current) {
        dropzoneInstance.current.destroy(); 
        dropzoneInstance.current = null
      }
    };
  }, [show,paperId]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
      <Modal.Body style={{ margin: "auto" }}>
        <form
          ref={dropzoneRef}
          id="upload-form"
          className="dropzone custom-dropzone"
        >
          <div className="previews"></div>
        </form>
      </Modal.Body>
      <Modal.Footer
        className="justify-content-center border-0"
        style={{ marginBottom: "30px" }}
      >
        <CustomButton rounded={true} onClick={handleUpload}>
          Upload
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
}

export default UploadDropzone;
