import * as React from "react";
import { Modal } from "react-bootstrap";
import DarkButton from "../DarkButton";
import "../../styles/reviewer/Components.css";

export default function DialogBox({
  open,
  setClose, // model close
  onClick, // right button handler
  loading,
  bodyText = "Once you submit, you can no longer edit the entered values.",
  leftButtonTitle = "Go Back",
  leftButtonVariant = "btn-outline-dark", // bootstrap button variants
  submitButtonTitle = "Submit",
  submitButtonVariant = "btn-dark",  // bootstrap button variants for submit 
}) {
  return (
    <Modal
      style={{ top: "10%" }}
      className="dialog-box"
      show={open}
      onHide={setClose}
    >
      <Modal.Header style={{ borderBottom: 0 }} closeButton>
        <Modal.Title>
          <strong className="fs-5">Are you sure?</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="">{bodyText}</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end border-0 gap-2">
        <DarkButton
          title={leftButtonTitle}
          variant={leftButtonVariant}
          className="m-0"
          onClick={setClose}
        />
        <DarkButton
          title={loading ? "Submitting..." : submitButtonTitle}
          className="m-0"
          variant={submitButtonVariant}
          onClick={onClick}
        />
      </Modal.Footer>
    </Modal>
  );
}
