// ** React Imports
import React, {useEffect, useState} from "react";

// ** React Bootstrap Imports
import {Card, Row} from 'react-bootstrap'

// ** Styles Imports
import '../../styles/reference-card.css'

const messageGroup = {
    passed: {title: 'Passed', description: 'You met all the necessary criteria for submission.', primaryColor: '#155724', secondaryColor: '#C3E6CB'},
    failed: {title: 'Failed', description: 'Unfortunately, your submission did not meet the necessary requirements.', primaryColor: '#721C24', secondaryColor: '#F5C6CB'},
    improvement: {title: 'Need Changes', description: 'Your citation needs to be improved further before submitting to the journal.', primaryColor: '#856404', secondaryColor: '#FFEEBA'},
}

const ReferenceResultCard = ({result}) => {
    const [message, setMessage] = useState({});

    useEffect(() => {
        let item = result.score > 7
            ? messageGroup.passed
            : messageGroup.improvement

        setMessage(item)

    }, []);

    return (
        <Card className="rounded-2 reference-result-card text-start mb-2" style={{borderColor: message?.secondaryColor}}>
            <Row className="p-1 m-0 fw-bolder" style={{color: '#333333'}}>
                <span className="p-0 me-1 opacity-50">Reference :</span>
                <span className="p-0 ms-2">{result?.citation}</span>
            </Row>
            <Row className="p-1 m-0 justify-content-between fw-bold align-items-center"
                 style={{color: message?.primaryColor}}>
                <span className="fs-4 p-0" style={{width: 'max-content'}}>{message?.title}</span>
                <span className="fs-3 p-0" style={{width: 'max-content'}}>{result?.score}/10</span>
            </Row>
            <Row className="p-1 m-0">
                <span className="p-0" style={{color: message.primaryColor}}>{message?.description}</span>

                {result && result.improvements.length > 0 ?
                    <div className="p-0 pt-3 fs-6" style={{color: message?.primaryColor}}>
                        Needed improvements
                        <ul className="">
                            {result?.improvements.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div> : ''
                }
            </Row>
        </Card>
    )
}

export default ReferenceResultCard
