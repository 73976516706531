import React from 'react'
import imgDelete from "../assets/icons8-delete-24.png";

// this component is used to display a list of members in projects
const MemberList = ({ list = [] }) => {
    return (
      <>
        {list.map((member) => (
          <MemberCard
            key={member.id}
            id={member.id}
            name={member.name}
            role={member.role}
            onDelete={member.onDelete}
          />
        ))}
      </>
    );
  };
  
  export default MemberList

  
//   This is the child component of MemberList show one member card
  const MemberCard = ({ id, name, role, onDelete }) => {
    return (
      <div id={id} className="d-grid border-bottom py-3 mb-2 w-100">
        <div className="row align-items-center">
          <div className="col text-secondary">{name}</div>
          <div className="col text-center text-secondary">{role}</div>
          <div className="col text-end" onClick={() => onDelete && onDelete(id)}>
            <img src={imgDelete} alt="delete-icon" width={18} style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>
    );
  };

