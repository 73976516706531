import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContextProvider';

const useFetch = (url, method = 'GET', payload = null, headers = {}) => {
  const {checkAuth,isAuth} = useContext(UserContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);


  if (!headers || Object.keys(headers).length === 0) {
    headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    };
  }

  const fetchData = async () => {
    try {
      let response;
      if (method === 'GET') {
        response = await axios.get(url, { headers });
      } else if (method === 'POST') {
        response = await axios.post(url, payload, { headers });
      } else if (method === 'PUT') {
        response = await axios.put(url, payload, { headers });
      } else if (method === 'DELETE') {
        response = await axios.delete(url, { data: payload, headers });
      }
      setData(response.data.data);
      console.log("usefetch",response.data);
    } catch (err) {
      setError('Failed to fetch data. Please try again later.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    (!isAuth) ? checkAuth(): fetchData();
  }, [url,isAuth,method,payload]);

  const refresh = () => {
    setLoading(true);
    fetchData();
  };

  return { data, error, loading ,refresh};
};

export default useFetch;
