import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL

// Create an axios instance with default settings
const client = () => {
  const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Interceptor to add the token from localStorage dynamically
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api;
};

export default client;
