// ** React Imports
import React, {useEffect, useState} from "react";

// ** React Icon Imports
import {HiBell} from "react-icons/hi";
import {BsClipboard2Data, BsPerson} from "react-icons/bs";
import {GoProject} from "react-icons/go";

// ** API Imports
import {getAllUserNotifications, updateAllNotificationReadStatus, updateOneNotificationReadStatus} from "../api/Api";
import {useNavigate} from "react-router-dom";

// ** toast import
import {showToast} from "../utils/toastUtils";

// ** Custom Imports
import CustomSkeleton from "../components/CustomSkeleton";

// ** Icon list
const icons = [
    {name:"FOLLOW", button_name:"View Profile", icon:<BsPerson size={25} className={"text-white"}/>},
    {name:"PROJECT_JOINED", button_name:"View Project", icon:<GoProject size={25} className={"text-white"}/>},
    {name:"REPORT_REVIEW", button_name:"View Report", icon:<BsClipboard2Data size={25} className={"text-white"}/>}
]

const Notifications = () => {

    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        loadAllNotifications()
    }, []);

    const loadAllNotifications = () => {
        setIsLoading(true)
        getAllUserNotifications().then(response => {
            if (response?.status === 200) {
                setNotifications(response.data)
            }
        }).finally(() => {setIsLoading(false)})
    }

    const markNotificationsAsRead = (notification_id) => {
        updateOneNotificationReadStatus(notification_id).then(response => {
            if (response?.status === 200) {
                loadAllNotifications()
            }
        })
    }

    const markAllNotificationsAsRead = () => {
        updateAllNotificationReadStatus().then(response => {
            if (response?.status === 200) {
                loadAllNotifications()
            }
        })
    }

    return (
        <div className="row pt-2">
            <div className="col-lg-8 pt-md-5 px-md-4">
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <HiBell className="text-color-cyan"/>
                        <span className="fs-6">Notification</span>
                    </div>

                    <div
                        onClick={markAllNotificationsAsRead}
                        className="text-color-cyan fw-bold text-decoration-underline fs-6"
                        style={{cursor: "pointer"}}
                    >
                        Mark all as read
                    </div>
                </div>
                <div className="d-flex pt-4 flex-column row-gap-1">

                    { !isLoading ? notifications && notifications.map((notification) => (
                        <NotificationCard
                            item={notification}
                            key={notification.id}
                            markNotificationsAsRead={markNotificationsAsRead}
                        />
                    )) :<CustomSkeleton count={10} height={90} style={{marginBottom:'10px'}} /> }
                </div>
            </div>
            <div className="col-lg-4 pt-md-5">
                <span className="fw-bold fs-6">You may also like</span>
            </div>
        </div>
    )
}

export default Notifications

const NotificationCard = ({item, markNotificationsAsRead}) => {
    const [notificationType, setNotificationType] = useState({});
    const navigate = useNavigate();

    const timeDuration = () => {
        const now = new Date();
        const createdAtDate = new Date(item.created_at);

        const diffInMs = now - createdAtDate;
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        if (diffInDays > 0) {
            return createdAtDate.toISOString().split("T")[0];
        } else if (diffInHours > 0) {
            return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
        } else {
            return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
        }
    }

    const redirect = () => {
        navigate(item.redirect_url)
    }

    useEffect(() => {
        const notification = icons.find(icon => icon.name === item.type);
        setNotificationType(notification)
    }, []);

    return (
        <div className="d-flex p-2 column-gap-2 w-100 border-bottom rounded-1"
             style={{backgroundColor:item.is_read ? "" : "#F6F8FA", opacity:item.is_read ? "0.9" : ""}}
             onClick={() => {
                 if (!item.is_read) {
                     markNotificationsAsRead(item.id);
                 }
             }}
        >

            <div className="px-2 d-flex" style={{height: "max-content"}}>
                <div className="rounded-2 d-flex justify-content-center align-items-center"
                     style={{width: "50px", height: "50px", backgroundColor: "#333"}}>
                    {notificationType.icon}
                </div>
            </div>
            <div className="d-flex flex-column w-100 row-gap-3">
                <div className="d-flex w-100 justify-content-between">
                    <span className="fw-semibold" style={{fontSize: "15px"}}>{item.message} !</span>
                    <div className={`d-flex border rounded-circle ${item.is_read ? "d-none" : ""}`}
                         style={{width: "max-content", height: "max-content", padding: "1px", borderColor: "#C4C4C4"}}>
                                    <span className={"rounded-circle d-block bg-custom-primary"}
                                          style={{width: "8px", height: "8px"}}></span>
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-between align-items-end">
                    <div
                        onClick={redirect}
                        className="text-color-cyan border border-2 px-md-3 py-md-1 px-2 rounded-2 fw-semibold border-color-primary"
                        style={{cursor: "pointer"}}
                    >
                        {notificationType.button_name}
                    </div>
                    <span className="text-color-tertiary" style={{fontSize: "12px"}}>{timeDuration()}</span>
                </div>
            </div>
        </div>
    )
}