import { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContextProvider";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../api/Api";
import {LoginSchema} from "../../utils/validations";
import {useFormik} from "formik";

const LoginForm = () => {
  const { login, isVerifyEmail } = useContext(UserContext);
  const navigate = useNavigate();

  // State to manage login data
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema:LoginSchema,
    onSubmit: async (values) => {
      handleOnSubmit(values);
    },
  });

  const handleOnSubmit = async (values) => {
      setError(null);
      setLoading(true);

      try {
        const emailVerification = await isVerifyEmail(values.email);
        if (emailVerification.status !== 200) {
          setError(emailVerification.message);
          setLoading(false);
          return;
        }

        const loginPayload = { email: values.email, password: values.password };
        const loginResponse = await login(loginPayload);

        if (loginResponse.status !== 200) {
          setError(loginResponse.message);
          setLoading(false);
          return;
        }

        const profileResponse = await getUserProfile();
        if (profileResponse.status !== 200) {
          setError(profileResponse.message);
          return;
        }

        navigate(profileResponse.data.data.isUpdated ? "/home" : "/extra/details");
      } catch (error) {
        setError("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
  }

  return (
      <div className="w-100">
        <div className="text-center">
          <h1>Login</h1>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label text-color-tertiary">
              <p className="small mb-0">E-mail</p>
            </label>
            <input
                type="email"
                placeholder="Enter your email"
                name="email"
                className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label text-color-tertiary">
              <p className="small mb-0">Password</p>
            </label>
            <input
                type="password"
                placeholder="Enter your password"
                name="password"
                className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password && (
                <div className="invalid-feedback">{formik.errors.password}</div>
            )}
          </div>

          <button
              type="submit"
              className="btn bg-custom-secondary btn-submit w-100 mt-3 py-3"
              disabled={loading || formik.isSubmitting}
          >
            <h6 className="mb-0">{loading ? 'Logging in...' : 'Login'}</h6>
          </button>
        </form>
      </div>
  );
};

export default LoginForm;
