import React, { useContext } from "react";
import "../../styles/reviewer/ReviewerLogin.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContextProvider";

const initialValues = {
  email: "",
  password: "",
};

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};

function ReviewerLogin() {
  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const onSubmit = async (values) => {
    const { email, password } = values;
    const role = "reviewer";

    const payload = { email, password, role };
    console.log("Formik values", payload);
    const response = await login(payload);
    console.log(response.status);
    if (response.status === 200) navigate("/reviewer/unassigned");
    else setError(response.message);
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  return (
    <div id="reviewer-login" className="container">
      <h1 className="title">
        <strong>Login</strong>
      </h1>
      <form onSubmit={formik.handleSubmit}>
        {/* Email Input */}
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Email"
            onBlur={formik.handleBlur} // onBlur is used to show the error message only after the user has visited the field
            onChange={formik.handleChange}
            value={formik.values.email}
            aria-describedby="emailHelp"
          />
          {formik.errors.email && formik.touched.email ? (
            <div>{formik.errors.email}</div>
          ) : null}
        </div>

        {/* Password Input */}
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            onBlur={formik.handleBlur} // onBlur is used to show the error message only after the user has visited the field
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.errors.password && formik.touched.password ? (
            <div>{formik.errors.password}</div>
          ) : null}
        </div>

        {/* Remember Me Checkbox */}
        <div className="form-group">
          <input
            type="checkbox"
            className="rm-checkbox"
            id="checkbox"
            // checked={rememberMe}
            // onChange={handleCheckboxChange}
          />
          <label htmlFor="checkbox">Remember Me</label>
        </div>

        {error && (
          <div className="alert alert-danger py-2" role="alert">
            {error}
          </div>
        )}
        {/* Submit Button */}
        <button type="submit" className="btn btn-dark rounded-0">
          Submit
        </button>
      </form>
    </div>
  );
}

export default ReviewerLogin;
