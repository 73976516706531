import { createContext, memo, useEffect, useState } from "react";
import axios from "axios";
import {showToast} from "../utils/toastUtils";

export const UserContext = createContext(null);

const UserContextProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isAuth, setIsAuth] = useState(false);

  const checkAuth = async () => {
    const token = localStorage.getItem("token");
    if (token) setIsAuth(true);
  }

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem("userInfo")));
    checkAuth();
  },[])

  const register = async (userData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/signup/`, userData);
      console.log(response.data);
      return response.data;
    } catch (error) {
      showToast(error.response?.data?.message || "Sign Up failed", "error");
      return error.response?.data;
    }
  };

  const login = async ({email, password, role}) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/login/`, { email, password, role });
      console.log(response.data);
      const token = response.data.data.access_token;

      if (token) {
        localStorage.setItem("token", token);
        setIsAuth(true);

        const data = {
          id: response.data.data.id,
          email: response.data.data.email,
          name: response.data.data.name,
          role: response.data.data?.role,
        };
        setUserInfo(data);
        localStorage.setItem("userInfo", JSON.stringify(data));
        console.log(data)
        return response.data;
      } else {
        console.error("Token not found in response");
        return response.data;
      }
    } catch (error) {
      console.error("Login failed:", error.response?.data.message || error.message);
      showToast(error.response?.data?.message || "Error Login in", "error");
      return error.response?.data || error;
    }
  };

  const isVerifyEmail = async (email) => {
    const encodedEmail = encodeURIComponent(email);
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/verify-email/?email=${encodedEmail}`);
        // Check if the response status is 200 and return true; otherwise return false
        return response.data;
    } catch (error) {
        console.error('Error verifying email:', error.message);
      showToast(error.response?.data?.message || "Error Verifying email", "error");
      return error.response?.data || error;
    }
};

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    setIsAuth(false);
    setUserInfo(null);
    console.log("User logged out");
  };

  const addUserInfo = (data) => {
    setUserInfo(data);
    localStorage.setItem("userInfo", JSON.stringify(data));
  };

  const value = {
    userInfo,
    isAuth,
    login,
    register,
    logout,
    checkAuth,
    isVerifyEmail,
    addUserInfo,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default memo(UserContextProvider);