import React from "react";
import Login from "../components/Login";

const LoginPage = () => {
    return (
        <div
            className="w-100 bg-custom-tertiary pt-md-5"
            style={{minHeight: "100vh"}}
        >

            <div className="">
                <Login/>

            </div>
        </div>
    );
};

export default LoginPage;
