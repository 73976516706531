import React from "react";

function Footer({className,style}) {
  return (
    <footer style={style} className={`d-flex justify-content-center border-top w-100 ${className? className:""}`}>
      <p className="opacity-50">Copyright © 2024</p>
    </footer>
  );
}

export default Footer;
