// src/pages/NotFound.js
import React from "react";

const NotFound = () => {
  return (
    <div className="container text-center my-5 py-5">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you're looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
