import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import { Stack } from "react-bootstrap";
import lineImage from "../../assets/Group 280.png";
import UploadDropzone from "./UploadDropzone";
import { useState } from "react";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

function AddNewArticle({ show, handleClose ,initiativeId, projectId}) {
  const [showDropzone, setShowDropzone] = useState(false);
  const handleUpload = () => {
    setShowDropzone(true);
    console.log("New Artical Added");
  };

  const handleDropzoneClose = () => {
    setShowDropzone(false);
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Stack direction="vertical" className="text-center d-block">
            <p>Share Your Amazing Thoughts With The World.</p>
            <img src={lineImage} alt="line" className="w-75" />
          </Stack>
        </Modal.Body>
        <Modal.Footer
          className="justify-content-center border-0 "
          style={{ marginBottom: "30px" }}
        >
          <CustomButton rounded={true} onClick={handleUpload}>
            <FileUploadOutlinedIcon/> Upload File
          </CustomButton>
        </Modal.Footer>
      </Modal>
      <UploadDropzone show={showDropzone} handleClose={handleDropzoneClose} initiativeId={initiativeId} projectId={projectId}/>
    </>
  );
}

export default AddNewArticle;
