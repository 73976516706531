import React, { useEffect, useRef } from "react";
import Dropzone from "dropzone";

function CustomDropzone({
    children,   // Children to be rendered inside the Dropzone
  style,
  className,
  setUploadData, // Function to set the data to be uploaded
  initUrl, // URL to upload the file when drag or add files to dropzone
  method = "POST", // Method to upload the file
  acceptedFiles = "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, image/jpeg, image/png, image/gif, application/zip, audio/mpeg, video/mp4, application/vnd.openxmlformats-officedocument.presentationml.presentation",
  dictDefaultMessage = "Drag here",
  destroyDep = [], // Dependencies to destroy the Dropzone instance
}) {
  const dropzoneRef = useRef(null); // Ref for the Dropzone element
  const dropzoneInstance = useRef(null); // Ref for the Dropzone instance

  const initializeDropzone = () => {
    if (dropzoneRef.current && !dropzoneInstance.current) {
      dropzoneInstance.current = new Dropzone(dropzoneRef.current, {
        url: initUrl, // Corrected the assignment
        method: method, // Corrected the assignment
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Cache-Control": "",
        },
        acceptedFiles: acceptedFiles, // Corrected the assignment
        addRemoveLinks: true,
        hiddenInputContainer: "body",
        maxFiles: 1, // Limit to 1 file
        dictDefaultMessage: dictDefaultMessage, // Corrected the assignment
        dictFallbackMessage:
          "Your browser does not support drag'n'drop file uploads.",
        init: function () {
          this.on("addedfile", function (file) {
            console.log("File added:", file);
          });
          this.on("success", function (file, response) {
            console.log("File uploaded successfully:", response);
            setUploadData(response.data);
            disableDropzone();
          });
          this.on("error", function (file, message) {
            file.previewElement.querySelector(".dz-error-message").textContent =
              "Failed to upload file.";
            console.log("Failed to upload file:", message);
            disableDropzone();
          });
          this.on("removedfile", function (file) {
            console.log("File removed:", file);
            enableDropzone(); // Enable Dropzone to accept new file after removal
          });
        },
      });
    }
  };

  const disableDropzone = () => {
    if (dropzoneInstance.current) {
      dropzoneInstance.current.options.clickable = false; // Disable the clickable option
      dropzoneInstance.current.disable(); // Disable Dropzone entirely
    }
  };

  const enableDropzone = () => {
    if (dropzoneInstance.current) {
      dropzoneInstance.current.options.clickable = true; // Re-enable clickable option
      dropzoneInstance.current.enable(); // Enable Dropzone
    }
  };

  useEffect(() => {
    if (dropzoneRef.current) {
      if (dropzoneInstance.current) {
        if (dropzoneInstance.current.options.clickable === false) {
          enableDropzone();
        }
      } else {
        initializeDropzone();
      }
    }

    return () => {
      if (dropzoneInstance.current) {
        dropzoneInstance.current.destroy();
        dropzoneInstance.current = null;
      }
    };
  }, destroyDep);

  return (
    <div >
      <form
        style={style}
        ref={dropzoneRef}
        id="upload-form"
        className={`dropzone ${className ? className : ""}`}
      >
        <div className="dz-default dz-message m-0">{children}</div>
      </form>
    </div>
  );
}

export default CustomDropzone;
