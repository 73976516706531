import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import CustomButton from "../CustomButton";
import axios from "axios"; // Import axios for making API calls
import { ProjectContext } from "../../contexts/ProjectContextProvider";

function CreateNewProject({ show, handleClose }) {
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { refresh } = useContext(ProjectContext);

  const handleNewProjectAdd = async () => {
    if (!projectName || !description) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    try {
      const payload = { name: projectName, description };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.status === 200) {
        console.log("Project added successfully:", response.data);
        setDescription("");
        setProjectName("");
        handleClose();
        refresh();
      } else {
        setErrorMessage("Failed to add project. Please try again. ");
      }

      console.log("Failed to add project:", response.data.message);
    } catch (error) {
      console.error("Failed to add project:", error);
      setErrorMessage("Failed to add project. Please try again.");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ borderBottom: 0 }} closeButton></Modal.Header>
        <Modal.Title style={{ textAlign: "center", marginBottom: "20px" }}>
          Create New Project
        </Modal.Title>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <Form style={{ width: "80%" }}>
            <Form.Group className="mb-3" controlId="projectName">
              <Form.Control
                type="text"
                placeholder="Project Name"
                autoFocus
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Control
                placeholder="Description"
                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{ border: 0, justifyContent: "center", marginBottom: "30px" }}
        >
          <CustomButton rounded={true} onClick={handleNewProjectAdd}>
            Add
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateNewProject;
